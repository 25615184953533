import { HoverCardContent } from '@radix-ui/react-hover-card'
import { useWindowSize } from '@uidotdev/usehooks'
import { TrophyIcon } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { HoverCard, HoverCardTrigger } from '../../../components/ui/hover-card'

type P = {
    prizes: PartnerEventPrize[]
}

const Prizes = ({prizes}:P) => {
    const { i18n, t } = useTranslation()
    const { width } = useWindowSize()

    function returnTranslationObject(translations:Translation[]) {
        return translations.find(i=>i.m_sLangCode===i18n.language.toUpperCase())
    }

  return (
    <>
    {groupPrizes(prizes) && Object.keys(groupPrizes(prizes)).map((division, ind) => {
        return (
            <div key={ind}>
                <p className="mb-2 font-semibold underline">{division}</p>
                <ul className="flex flex-col gap-2">
                    {groupPrizes(prizes)[division].map((group, index) => (
                        <li key={index} className="ml-2">
                            <span className="font-semibold">{t("event.place")} {group.position}.</span>
                            <ul className="ml-4 list-disc">
                                {group.prizes.map((prize, pIndex) => (
                                    <HoverCard key={pIndex} openDelay={100} closeDelay={0}>
                                        <HoverCardTrigger asChild>
                                            <li key={pIndex}>
                                                <span>{prize?.m_sName}</span>
                                            </li>
                                        </HoverCardTrigger>
                                        <HoverCardContent>
                                            <img src={prize?.m_sImageUrl} alt="" />
                                        </HoverCardContent>
                                    </HoverCard>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            </div>
        );
    })}
    {/* {groupPrizes(prizes) && Object.keys(groupPrizes(prizes)).map((k)=>(
        <div className='flex flex-col gap-4'>
            <p className='text-lg font-medium text-center underline'>{k}</p>

            <div className='grid gap-4 md:grid-cols-3'>

                {groupPrizes(prizes)[k].map((prize)=>{
                    let position = parseFloat(prize.m_sPosition)
                    if(width > 768 && parseFloat(prize.m_sPosition) < 4){                   
                        let bgColor:string = ''
                        let colStart:string = ''
                        switch (parseFloat(prize.m_sPosition)) {
                            case 1:
                                colStart = '2/3'
                                bgColor = '#E7D38A'
                                break;
                            case 2:
                                colStart = '1/2'
                                bgColor = '#DDDDDD'
                                break;
                            case 3:
                                colStart = '3/4'
                                bgColor = '#D9A962'
                                break;
                            default:
                                break;
                        }

                        return (
                            <div style={{gridColumn:colStart, gridRow:1}} className='flex flex-col items-center justify-end gap-4'>
                                <div className='flex items-center justify-center w-32 h-32 overflow-hidden rounded-lg shadow-xl bg-white/10 shadow-white/10'><img className='w-full h-full' src={prize.m_oPrize.m_sImageUrl} /></div>
                                <p className='mb-4 text-5xl font-bold text-center'>{prize.m_sPosition}</p>

                                <div className='min-h-[210px] w-full relative'>
                                    <div style={{backgroundColor:bgColor}} className='absolute flex items-center justify-center w-10 h-10 -translate-x-1/2 rounded-md -top-2 left-1/2'>
                                        <img className='w-5 h-5 opacity-30' src={'/assets/event/trophy.png'} />
                                    </div>

                                    <p className='absolute text-center left-4 top-16 w-[calc(100%-32px)] text-sm'>{returnTranslationObject(prize.m_oPrize.m_aTranslations)?.m_sNameTransl || prize.m_sPrize}</p>

                                    <img src={position === 1 ? '/assets/event/podium-first.svg' : '/assets/event/podium-second.svg'} />
                                </div>
                            </div>
                        )
                    }
                    return (
                        <>
                        {width > 768 && parseFloat(prize.m_sPosition) === 4 && <div className='h-10 col-span-full'></div>}
                        

                        <div className='flex flex-col gap-4 group'>
                            <div className='bg-white/5 rounded-xl p-4 min-h-[200px] relative overflow-hidden'>
                                <p className='relative z-10 text-sm font-semibold'>Prijs</p>
                                <p className='relative z-10 text-3xl font-bold text-left'>{prize.m_sPosition}</p>
                                <div className={`${width > 768 ? 'from-tifo/90 to-tifo/0' : (position < 4 ? (position === 1 ? 'from-amber-500/90 to-amber-500/0' : (position === 2 ? 'from-neutral-500/90 to-neutral-500/0' : 'from-amber-700/90 to-amber-700/0')) : 'from-tifo/90 to-tifo/0')} absolute z-[1] top-0 left-0 w-full h-2/3 bg-gradient-to-b`}></div>
                                <img className={`${(width <= 768 && position < 4) && '!grayscale-0'} md:grayscale group-hover:grayscale-0 absolute top-0 left-0 w-full h-full object-cover duration-200`} src={prize.m_oPrize.m_sImageUrl} />
                            </div>
                            <p className='text-sm text-center text-white/70'>{returnTranslationObject(prize.m_oPrize.m_aTranslations)?.m_sNameTransl || prize.m_sPrize}</p>
                        </div>
                        
                        </>
                    )
                })}
            </div>
        </div>
    ))} */}
    </>
  )
}

export default Prizes

export function groupPrizes(prizes: PartnerEventPrize[]) {
    if (!Array.isArray(prizes)) return null;

    const parsePosition = (pos: string) => parseInt(pos, 10);
    const createRangeString = (start: number, end: number) => (start === end ? `${start}` : `${start}-${end}`);

    // Group by `m_sPrizeDivision` first
    let groupedByDivision = prizes.reduce((r, a) => {
        if (a.m_sPrizeDivision?.toLowerCase() === "per partner") {
            a.m_sPrizeDivision = a.m_sPartner;
        }
        r[a.m_sPrizeDivision] = r[a.m_sPrizeDivision] || [];
        r[a.m_sPrizeDivision].push(a);
        return r;
    }, Object.create(null));

    let result: Record<string, { position: string; prizes: PartnerPrize[] }[]> = {};

    for (let division in groupedByDivision) {
        let divisionPrizes = groupedByDivision[division];

        // Group by position first (ensuring all prizes at the same position are together)
        let positionMap = new Map<number, Set<PartnerPrize>>();

        for (let prize of divisionPrizes) {
            console.log(prize)
            let position = parsePosition(prize.m_sPosition);
            if (!positionMap.has(position)) {
                positionMap.set(position, new Set());
            }
            positionMap.get(position)?.add(prize.m_oPrize);
        }

        // Convert map to sorted array
        let sortedPositions = [...positionMap.entries()].sort((a, b) => a[0] - b[0]);

        // Merge consecutive positions with identical prizes
        let groupedPrizes: { position: string; prizes: PartnerPrize[] }[] = [];
        let currentStart = sortedPositions[0][0];
        let currentPrizes = sortedPositions[0][1];
        let lastPosition = currentStart;

        for (let i = 1; i < sortedPositions.length; i++) {
            let [pos, prizes] = sortedPositions[i];

            if (JSON.stringify([...prizes]) === JSON.stringify([...currentPrizes]) && pos === lastPosition + 1) {
                // Extend the range
                lastPosition = pos;
            } else {
                // Save previous range and start a new one
                groupedPrizes.push({
                    position: createRangeString(currentStart, lastPosition),
                    prizes: [...currentPrizes]
                });

                currentStart = pos;
                lastPosition = pos;
                currentPrizes = prizes;
            }
        }

        // Add the last group
        groupedPrizes.push({
            position: createRangeString(currentStart, lastPosition),
            prizes: [...currentPrizes]
        });

        result[division] = groupedPrizes;
    }

    return result;
}



// export function groupPrizes(prizes: PartnerEventPrize[]) {
//     if (!Array.isArray(prizes)) return null;

//     const parsePosition = (pos: string) => parseInt(pos, 10);

//     // First, group by `m_sPrizeDivision`
//     let groupedByDivision = prizes.reduce((r, a) => {
//         if (a.m_sPrizeDivision?.toLowerCase() === "per partner") {
//             a.m_sPrizeDivision = a.m_sPartner;
//         }
//         r[a.m_sPrizeDivision] = r[a.m_sPrizeDivision] || [];
//         r[a.m_sPrizeDivision].push(a);
//         return r;
//     }, Object.create(null));

//     let result: Record<string, Record<number, PartnerEventPrize[]>> = {};

//     for (let division in groupedByDivision) {
//         let divisionPrizes = groupedByDivision[division];

//         // Sort prizes by position numerically
//         divisionPrizes.sort((a, b) => parsePosition(a.m_sPosition) - parsePosition(b.m_sPosition));

//         let groupedByPosition: Record<number, PartnerEventPrize[]> = {};

//         for (let prize of divisionPrizes) {
//             let position = parsePosition(prize.m_sPosition);
//             if (!groupedByPosition[position]) {
//                 groupedByPosition[position] = [];
//             }
//             groupedByPosition[position].push(prize);
//         }

//         result[division] = groupedByPosition;
//     }

//     return result;
// }

// export function groupPrizes(prizes:PartnerEventPrize[]){
//     if(!Array.isArray(prizes)){ return null }

//     // Function to parse position into a number
//     const parsePosition = (pos: string) => parseInt(pos, 10);

//     // Function to create a range string
//     const createRangeString = (start: number, end: number) => start === end ? `${start}` : `${start}-${end}`;

//      // Group by m_sPrizeDivision first
//     let groupedByDivision = prizes.reduce((r, a) => {
//         if(a.m_sPrizeDivision?.toLowerCase() === "per partner"){ a.m_sPrizeDivision = a.m_sPartner }
//         r[a.m_sPrizeDivision] = r[a.m_sPrizeDivision] || [];
//         r[a.m_sPrizeDivision].push(a);
//         return r;
//     }, Object.create(null));

//     // Result object to store grouped prizes
//     let result: Record<string, PartnerEventPrize[]> = {};

//     for (let division in groupedByDivision) {
//         let divisionPrizes = groupedByDivision[division];

//         // Sort by m_sPosition numerically
//         divisionPrizes.sort((a, b) => parsePosition(a.m_sPosition) - parsePosition(b.m_sPosition));

//         let groupedPrizes: PartnerEventPrize[] = [];
//         let currentGroup: PartnerEventPrize[] = [];
//         let currentPrize = divisionPrizes[0];
//         let currentStart = parsePosition(currentPrize.m_sPosition);

//         for (let i = 0; i < divisionPrizes.length; i++) {
//             let prize = divisionPrizes[i];
//             let position = parsePosition(prize.m_sPosition);

//             // Check if the prize is the same and the position is consecutive
//             if (prize.m_sPrize === currentPrize.m_sPrize && (i === 0 || position === parsePosition(currentPrize.m_sPosition) + 1)) {
//                 currentGroup.push(prize);
//             } else {
//                 // Group completed, add to groupedPrizes
//                 let lastPosition = parsePosition(currentGroup[currentGroup.length - 1].m_sPosition);
//                 let rangeString = createRangeString(currentStart, lastPosition);
//                 let combinedPrize = { ...currentPrize, m_sPosition: rangeString };
//                 groupedPrizes.push(combinedPrize);

//                 // Start a new group
//                 currentGroup = [prize];
//                 currentStart = position;
//             }

//             // Update currentPrize for the next iteration
//             currentPrize = prize;
//         }

//         // Add the last group
//         if (currentGroup.length) {
//             let lastPosition = parsePosition(currentGroup[currentGroup.length - 1].m_sPosition);
//             let rangeString = createRangeString(currentStart, lastPosition);
//             let combinedPrize = { ...currentGroup[0], m_sPosition: rangeString };
//             groupedPrizes.push(combinedPrize);
//         }

//         // Assign to the result
//         result[division] = groupedPrizes;
//     }

//     return result
// }