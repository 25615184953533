import React, { useState } from 'react'
import { HelpCircle, Home, Trophy, View, Award, Scroll, X, Lock, Loader2, AreaChartIcon } from 'lucide-react'
import Navigation from '../../components/pages/prediction/Navigation'
import Game from '../../components/pages/prediction/Game'
import TabsRanking from '../../components/pages/prediction/TabsRanking'
import SmallRankingsTable from '../../components/rankings/SmallRankingsTable'
import { AlertDialog, AlertDialogCancel, AlertDialogContent, AlertDialogTrigger } from '../../components/ui/alert-dialog'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import axios from '../../lib/axios'
import { format, isBefore, isValid } from 'date-fns'
import ProfilePicture from '../../components/riders/ProfilePicture'
import useAuth from '../../hooks/useAuth'
import { toEventOrPartner } from '../../lib/linking'
import { getTranslationString } from '../../lib/translation'
import { useTranslation } from 'react-i18next'
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../../components/ui/hover-card'
import { CYCLO_PARTNER_CONFIG, getCycloPartnerConfig } from '../../config/cyclogame-partner-config'
import { groupPrizes } from '../Event/prizes/Prizes'

const gameObject = {
  backgroundImage: 'https://cdn.qastan.be/Tifogame/algemeen-beeld.jpg'
}

const Prono = () => {
  const {t, i18n} = useTranslation()
  const {auth} = useAuth()
  const {pronoid, partnername, eventname} = useParams()
  const navigate = useNavigate()
  const [active_ranking, setactive_ranking] = useState<"company" | "group" | "groups">('company');

  const [run_game_steps, setrun_game_steps] = useState(false);

  const { data: prono, isLoading:isLoadingProno } = useQuery<Prono>({
    queryKey: [`prono_${pronoid}`],
    queryFn: async () => {
        try {
            const {data} = await axios.get(`pronos/${pronoid}`)
            if(Array.isArray(data)){ navigate('/') }
            return data
        } catch (error) {
            console.log("/pronos", error)
            navigate('/')
            return null
        }
    },
    refetchOnWindowFocus: false
  })

  const { data:results } = useQuery<PronoResult[] | null>({
    queryKey: [`results_${pronoid}`],
    queryFn: async () => {
      try {
        const { data } = await axios.get(`pronos/${pronoid}/results`)
        if(!Array.isArray(data)) { return null }
        let filteredData = data.filter(i=>parseFloat(i.m_sPosition)<=3)
        return filteredData
      } catch (error) {
        return null
      }
    },
    enabled: (!!prono && prono.m_bClosed),
    refetchOnWindowFocus: false
  })

  const { data:results_user } = useQuery<UserPronoResult[] | null>({
    queryKey: [`prevPicked_${pronoid}`],
    queryFn: async () => {
      try {
        const { data } = await axios.get(`user-prono-results?userid=${auth.data.m_iUserID}&pronoid=${pronoid}`)
        let results = data.filter(i=>parseFloat(i.m_sPosition)<=3)
        console.log(results)
        return results
      } catch (error) {
        return null
      }
    },
    enabled: (!!prono && prono.m_bClosed),
    refetchOnWindowFocus: false
  })

  const { data: competitors } = useQuery<PronoCompetitors[] | null>({
    queryKey: [`competitors_${pronoid}`],
    queryFn: async () => {
      try {
        const { data } = await axios.get(`pronos/${pronoid}/competitors`)
        if (!Array.isArray(data)) { throw new Error('No array') }
        return data
      } catch (error) {
        console.log("/competitors", error)
      }
      return null
    },
    refetchOnWindowFocus: false
  })

  const { data: event} = useQuery<EventClass | null>({
    queryKey: [`event_${eventname}`],
    queryFn: async () => {
      try {
        const {data} = await axios.get(`event/${eventname.toLowerCase()}`)
        if(Array.isArray(data)){throw new Error('multiple events found')}
        return data
      } catch (error) {
        console.log("/event", error)
        navigate('/')
        return null
      }
    },
    refetchOnWindowFocus: false
  })

  const { data:pronos } = useQuery<Prono[] | null>({
    queryKey: [`pronos_${eventname}`], 
    queryFn: async () => {
      try {
        const { data } = await axios.get(`events/${event.m_iEventID}/pronos`)
        return data
      } catch (error) {
        console.log(error)
        return null
      }
    },
    enabled: !!event,
    refetchOnWindowFocus: false
  })

  const { data:prizes, isFetching:isFetchingPrizes } = useQuery<PartnerEventPrize[] | null>({
    queryKey: [`prizes_${partnername}_${eventname}`],
    queryFn: async () => {
      try {
        if(!partnername){return null;}
        const { data } = await axios.get(`partner/${partnername}/event/${eventname.toLowerCase()}`)
        if (data.m_aPartnerEventPrizes) {
          data.m_aPartnerEventPrizes.sort((a,b)=>a.m_sPosition-b.m_sPosition)
            return data.m_aPartnerEventPrizes
          }
        else return []
      } catch (error) {
          console.log(error)
          return null
      }
    },
    refetchOnWindowFocus: false
  })

  const { data:tiebreaker, refetch:refetchTiebreaker } = useQuery<TiebreakerAnswer | null>({
    queryKey: [`tiebreaker_${prono?.m_iPronoID}`],
    queryFn: async () => {
      try {
        const { data } = await axios.get(`tie-breaker?pronoid=${prono.m_iPronoID}`)
        if(!data){ throw new Error() }
        console.log(data);
        return data
      } catch (error) {
        console.log('');
        return null
      }
    },
    enabled: !!prono,
  })

  function returnTranslationObject(translations:Translation[]) {
    return translations.find(i=>i.m_sLangCode===i18n.language.toUpperCase())
  }

  // function groupPrizes(prizes: PartnerEventPrize[]) {
  //   if (!Array.isArray(prizes)) return null;

  //   const parsePosition = (pos: string) => parseInt(pos, 10);

  //   // First, group by `m_sPrizeDivision`
  //   let groupedByDivision = prizes.reduce((r, a) => {
  //       if (a.m_sPrizeDivision?.toLowerCase() === "per partner") {
  //           a.m_sPrizeDivision = a.m_sPartner;
  //       }
  //       r[a.m_sPrizeDivision] = r[a.m_sPrizeDivision] || [];
  //       r[a.m_sPrizeDivision].push(a);
  //       return r;
  //   }, Object.create(null));

  //   let result: Record<string, Record<number, PartnerEventPrize[]>> = {};

  //   for (let division in groupedByDivision) {
  //       let divisionPrizes = groupedByDivision[division];

  //       // Sort prizes by position numerically
  //       divisionPrizes.sort((a, b) => parsePosition(a.m_sPosition) - parsePosition(b.m_sPosition));

  //       let groupedByPosition: Record<number, PartnerEventPrize[]> = {};

  //       for (let prize of divisionPrizes) {
  //           let position = parsePosition(prize.m_sPosition);
  //           if (!groupedByPosition[position]) {
  //               groupedByPosition[position] = [];
  //           }
  //           groupedByPosition[position].push(prize);
  //       }

  //       result[division] = groupedByPosition;
  //   }

  //   return result;
  // }

  // function groupPrizes(prizes:PartnerEventPrize[]){
  //   if(!Array.isArray(prizes)){ return null }

  //   // Function to parse position into a number
  //   const parsePosition = (pos: string) => parseInt(pos, 10);

  //   // Function to create a range string
  //   const createRangeString = (start: number, end: number) => start === end ? `${start}` : `${start}-${end}`;

  //    // Group by m_sPrizeDivision first
  //   let groupedByDivision = prizes.reduce((r, a) => {
  //     if(a.m_sPrizeDivision?.toLowerCase() === "per partner"){ a.m_sPrizeDivision = a.m_sPartner }
  //     r[a.m_sPrizeDivision] = r[a.m_sPrizeDivision] || [];
  //     r[a.m_sPrizeDivision].push(a);
  //     return r;
  //   }, Object.create(null));

  //   // Result object to store grouped prizes
  //   let result: Record<string, PartnerEventPrize[]> = {};

  //   for (let division in groupedByDivision) {
  //       let divisionPrizes = groupedByDivision[division];

  //       // Sort by m_sPosition numerically
  //       divisionPrizes.sort((a, b) => parsePosition(a.m_sPosition) - parsePosition(b.m_sPosition));

  //       let groupedPrizes: PartnerEventPrize[] = [];
  //       let currentGroup: PartnerEventPrize[] = [];
  //       let currentPrize = divisionPrizes[0];
  //       let currentStart = parsePosition(currentPrize.m_sPosition);

  //       for (let i = 0; i < divisionPrizes.length; i++) {
  //           let prize = divisionPrizes[i];
  //           let position = parsePosition(prize.m_sPosition);

  //           // Check if the prize is the same and the position is consecutive
  //           if (prize.m_sPrize === currentPrize.m_sPrize && (i === 0 || position === parsePosition(currentPrize.m_sPosition) + 1)) {
  //               currentGroup.push(prize);
  //           } else {
  //               // Group completed, add to groupedPrizes
  //               let lastPosition = parsePosition(currentGroup[currentGroup.length - 1].m_sPosition);
  //               let rangeString = createRangeString(currentStart, lastPosition);
  //               let combinedPrize = { ...currentPrize, m_sPosition: rangeString };
  //               groupedPrizes.push(combinedPrize);

  //               // Start a new group
  //               currentGroup = [prize];
  //               currentStart = position;
  //           }

  //           // Update currentPrize for the next iteration
  //           currentPrize = prize;
  //       }

  //       // Add the last group
  //       if (currentGroup.length) {
  //           let lastPosition = parsePosition(currentGroup[currentGroup.length - 1].m_sPosition);
  //           let rangeString = createRangeString(currentStart, lastPosition);
  //           let combinedPrize = { ...currentGroup[0], m_sPosition: rangeString };
  //           groupedPrizes.push(combinedPrize);
  //       }

  //       // Assign to the result
  //       result[division] = groupedPrizes;
  //   }

  //   return result
  // }
  
  return (
    <>
    {isLoadingProno && <div className='fixed z-10 flex items-center justify-center w-10 h-10 bg-white rounded-full shadow-md bottom-4 left-1/2 -translate-1/2'><Loader2 className='w-7 animate-spin text-neutral-400'/></div>}
    <div className='relative'>
      {/* Background */}
      <div className='fixed w-full h-full top-0 z-[1] bg-black/40'></div>
      <img className={`${prono?.m_bClosed && 'grayscale'} fixed object-cover w-full h-full object-center`} src={prono ? prono.m_sImageUrl ? prono.m_sImageUrl : gameObject.backgroundImage : gameObject.backgroundImage} alt="" />      

      {/* Content */}
      <div className='relative z-[2] pt-[88px]'>
        
        
        <div className='grid w-full max-w-5xl grid-cols-3 px-4 mx-auto translate-y-4 lg:px-0'>
        <Link to={`/${toEventOrPartner(auth)}/${eventname}`} className='w-fit'><button className='flex items-center justify-center w-10 h-10 duration-200 rounded-full backdrop-blur-sm bg-black/40 hover:bg-black/50'><Home className='w-5 text-white'/></button></Link>
        {prono && <div className='flex items-center justify-center'><Navigation prono={prono} pronos={pronos} className=''/></div>}
        {/* {pronos && prono && !prono?.m_bClosed && <div className='flex items-center justify-end'><HelpContainer onStartTutorial={() => { setrun_game_steps(true) }} /></div>}  */}
        </div>

        <div className='flex flex-col items-center gap-2 pt-16'>
          <p className='font-semibold text-white uppercase'>{prono && isValid(new Date(prono?.m_dtStart)) && format(new Date(prono?.m_dtStart) , "dd MMMM yyyy - HH:mm")}</p>
          <p className='text-4xl font-light text-center text-white sm:text-5xl'>
            {prono && (auth.data ? getTranslationString(i18n.language.toUpperCase() as Language, prono, "m_sDescriptionNL") : getTranslationString(i18n.language.toUpperCase() as Language, prono, "m_sDescriptionNL"))}
          </p>
          <p className='flex items-center gap-4 text-2xl font-bold text-white uppercase sm:text-3xl'>
            {prono?.m_bClosed ? <Lock className='w-6 h-6 sm:w-9 sm:h-9'/> : (prono && prono.m_oPronoType && prono.m_oPronoType.m_sImageUrl && <img className='w-12 h-12 md:w-16 md:h-16 invert' src={prono.m_oPronoType.m_sImageUrl} />)}
            { prono
              ? getTranslationString(i18n.language.toUpperCase() as Language, prono.m_oPronoType, "m_sNameNL") 
              : prono?.m_oPronoType.m_sNameNL
            }
          </p>
        </div>

        {(prono && !prono?.m_bClosed) &&
        <div className={`grid-cols-3 mt-16 pb-16 grid gap-12 mx-4 sm:mx-16`}>
          <div className={`${(Array.isArray(prizes) || (pronos && pronos.some(i=>i.m_bClosed))) ? 'col-span-3 lg:col-span-2' : 'col-span-full lg:col-span-2'} h-fit relative bg-black/20 backdrop-blur-md p-4 rounded-md pt-8 sm:pt-4`}>
            <Game onStartTutorial={() => { setrun_game_steps(true) }} onEnterData={()=>{refetchTiebreaker()}} run_game_steps={run_game_steps} setrun_game_steps={setrun_game_steps} prono={prono} competitors={competitors} event={event}/>
          </div>
          
          
          <div className='grid grid-cols-1 col-span-3 gap-12 lg:col-span-1 md:grid-cols-2 lg:gap-0 lg:flex lg:flex-col'>
            {/* Ride profile */}
            {getCycloPartnerConfig(auth.data.m_oPartner.m_iPartnerID).prono.view_ride_profile && 
            <div className='z-10 w-full p-4 text-white rounded-md col-span-full bg-black/20 backdrop-blur-md h-fit lg:mb-12'>
              <p className='flex items-center gap-2 mb-2 font-semibold text-white'><span><AreaChartIcon className='w-5'/></span>Rit profiel</p>
              <div className='overflow-hidden rounded-sm'><img className='object-cover w-full h-full' src={`https://cdn.qastan.be/Tifogame/cycling/stageprofiles/${prono.m_iEventID}/${prono.m_iPronoID}.png`} alt="Rit profiel"/></div>
            </div>
            }

            {/* Awards */} 
            <div className='z-10 w-full p-4 text-white rounded-md bg-black/20 backdrop-blur-md h-fit lg:mb-12'>
              <p className='flex items-center gap-2 mb-2 font-semibold text-white'><span><Trophy className='w-5'/></span>{t("prono.prizes_title")}</p>
              <div className='flex flex-col gap-4 mt-4'>
              {groupPrizes(prizes) && Object.keys(groupPrizes(prizes)).map((division, ind) => {
                return (
                    <div key={ind}>
                        <p className="mb-2 font-semibold underline">{division}</p>
                        <ul className="flex flex-col gap-2">
                            {groupPrizes(prizes)[division].map((group, index) => (
                                <li key={index} className="ml-2">
                                    <span className="font-semibold">{t("event.place")} {group.position}.</span>
                                    <ul className="ml-4 list-disc">
                                        {group.prizes.map((prize, pIndex) => (
                                          <HoverCard key={pIndex} openDelay={100} closeDelay={0}>
                                            <HoverCardTrigger asChild>
                                                <li key={pIndex}>
                                                    <span>{prize?.m_sName}</span>
                                                </li>
                                            </HoverCardTrigger>
                                            <HoverCardContent>
                                                <img src={prize?.m_sImageUrl} alt="" />
                                            </HoverCardContent>
                                          </HoverCard>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    </div>
                );
            })}
                
                {/* {groupPrizes(prizes) && Object.keys(groupPrizes(prizes)) && Object.keys(groupPrizes(prizes)).map((key, ind)=>{
                  return (
                      <div key={ind}>
                          <p className='mb-2 font-semibold underline'>{key}</p>
                          <ul className='flex flex-col gap-2'>
                          {groupPrizes(prizes)[key].map((prize, index)=>{
                              if(prize.m_oPrize.m_sImageUrl!==''){
                                  return (
                                      <HoverCard openDelay={100} closeDelay={0} key={index}>
                                          <HoverCardTrigger asChild><li className='ml-2 w-fit'><span className='font-semibold'>{t("event.place")} {prize.m_sPosition}.</span> {returnTranslationObject(prize.m_oPrize.m_aTranslations)?.m_sNameTransl || prize.m_sPrize}</li></HoverCardTrigger>
                                          <HoverCardContent className='flex items-center justify-center'>
                                              <img className='max-h-[150px]' src={prize.m_oPrize.m_sImageUrl} alt="" />
                                          </HoverCardContent>
                                      </HoverCard>
                                  )
                              }
                              return (
                                  <li key={index} className='ml-2'><span className='font-semibold'>{t("event.place")} {prize.m_sPosition}.</span> {returnTranslationObject(prize.m_oPrize.m_aTranslations)?.m_sNameTransl || prize.m_sPrize}</li>
                              )
                          })}
                          </ul>
                      </div>
                  )
                })} */}
              </div>
              {((!prizes && !isFetchingPrizes) || (prizes && prizes.length===0)) && <p className='text-white'>{t("prono.prizes_none")}</p>}
            </div>

            {/* Tiebreaker */} 
            {(!!tiebreaker && !!tiebreaker.m_nUserAnswer) && 
            <div className='w-full p-4 text-white rounded-md bg-black/20 backdrop-blur-md h-fit lg:mb-12'>
              <p className='flex items-center gap-2 mb-2 font-semibold'><span><HelpCircle className='w-5'/></span>{t("prono.o_tiebreaker.title")}</p>
              <p className='font-medium'>{getTranslationString(i18n.language.toUpperCase() as Language, tiebreaker.m_oQuestion, 'm_sQuestionNL')}</p>
              <p className='text-white/50'>{t("prono.o_tiebreaker.answer")}: <span className='font-semibold text-tifo-light'>{(Math.round(tiebreaker.m_nUserAnswer*100)/100)} km/u</span></p>
            </div>
            }
            
            {/* Rankings */}
            {(auth.data && event) && 
            <div>
              <TabsRanking value={active_ranking} onValueChange={(value)=>{setactive_ranking(value)}}/>
              
              <div className='relative p-4 mt-10 rounded-md bg-black/20 backdrop-blur-md'>
                <div className='absolute top-0 z-10 px-3 py-1 font-semibold uppercase -translate-y-1/2 shadow-sm bg-tifo text-tifo-light left-4'>{t("prono.ranking")}</div>
                <SmallRankingsTable 
                  eventid={event.m_iEventID} 
                  partnerid={(active_ranking === 'company' || active_ranking === 'groups') ? (auth?.data?.m_oPartner?.m_iPartnerID || undefined) : undefined} 
                  departmentid={active_ranking === 'group' ? auth.data.m_oDepartment.m_iDepartmentID : undefined} 
                  view={active_ranking === 'company' ? 'partner' : (active_ranking === 'groups' ? 'groups' : 'department')}/>
              </div>
            </div>
            }
          </div>
          
        </div>
        }

        {prono && prono.m_bClosed &&
          <>
          <div className='flex justify-center gap-12 pb-16 mx-4 mt-16 sm:mx-16'>
            <div className='relative grid gap-4 p-4 text-white rounded-md md:flex bg-black/20 backdrop-blur-md w-fit'>

              <div className='flex flex-col flex-wrap items-center justify-between'>
                <p className='text-lg font-bold'>{t("prono.result")}</p>
                <div className='grid grid-cols-3 gap-4 mt-8'>
                  <div className='flex flex-col items-center justify-start text-white sm:justify-end'>
                    <p className='mb-4 text-lg font-semibold'>2</p>
                    <ProfilePicture image={results?.find(i=>i.m_sPosition==='2')?.m_oCompetitor.m_sImageUrl} nationality={results?.find(i=>i.m_sPosition==='2')?.m_oCompetitor.m_sNationality.toLowerCase() as Nationality} size='md' />
                    <p className='mt-4 font-medium text-center uppercase'>{results?.find(i => i.m_sPosition === '2')?.m_oCompetitor.m_sName}</p><p className='text-sm text-center opacity-50'>{results?.find(i => i.m_sPosition === '2')?.m_oCompetitor.m_sTeam}</p>
                  </div>

                  <div className='flex flex-col items-center justify-start text-white sm:justify-end'>
                    <p className='mb-4 text-lg font-semibold'>1</p>
                    <ProfilePicture image={results?.find(i => i.m_sPosition === '1')?.m_oCompetitor.m_sImageUrl} nationality={results?.find(i => i.m_sPosition === '1')?.m_oCompetitor.m_sNationality.toLowerCase() as Nationality} size='lg' />
                    <p className='mt-4 font-medium text-center uppercase'>{results?.find(i => i.m_sPosition === '1')?.m_oCompetitor.m_sName}</p><p className='text-sm text-center opacity-50'>{results?.find(i => i.m_sPosition === '1')?.m_oCompetitor.m_sTeam}</p>
                  </div>

                  <div className='flex flex-col items-center justify-start text-white sm:justify-end'>
                    <p className='mb-4 text-lg font-semibold'>3</p>
                    <ProfilePicture image={results?.find(i => i.m_sPosition === '3')?.m_oCompetitor.m_sImageUrl} nationality={results?.find(i => i.m_sPosition === '3')?.m_oCompetitor.m_sNationality.toLowerCase() as Nationality} size='md' />
                    <p className='mt-4 font-medium text-center uppercase'>{results?.find(i => i.m_sPosition === '3')?.m_oCompetitor.m_sName}</p><p className='text-sm text-center opacity-50'>{results?.find(i => i.m_sPosition === '3')?.m_oCompetitor.m_sTeam}</p>
                  </div>

                </div>
              </div>

              {(auth.data && results_user) &&
              <>
              <div className='w-full h-px md:w-px md:h-full bg-white/20'></div>

              <div className='flex flex-col items-center justify-between'>
                <p className='text-lg font-bold'>{t("prono.your_prono")}</p>
                <div className='grid grid-cols-3 gap-4 mt-8'>
                  <div className='flex flex-col items-center justify-start text-white sm:justify-end'>
                    <p className='mb-4 text-lg font-semibold'>2</p>
                    <ProfilePicture glow={!!(results_user?.find(i => i.m_sPosition === '2')?.m_iPoints > 0)} image={results_user?.find(i => i.m_sPosition === '2')?.m_oCompetitor.m_sImageUrl} nationality={results_user?.find(i => i.m_sPosition === '2')?.m_oCompetitor.m_sNationality.toLowerCase() as Nationality} size='md' />
                    <div className='flex flex-col items-center justify-center mt-4'>
                    {(results_user?.find(i => i.m_sPosition === '2')?.m_iPoints > 0) && <p className='text-sm font-medium text-tifo-light'>+{results_user?.find(i => i.m_sPosition === '2')?.m_iPoints} ptn</p>}
                      <p className='font-medium text-center uppercase'>{results_user?.find(i => i.m_sPosition === '2')?.m_oCompetitor.m_sName}</p><p className='text-sm text-center opacity-50'>{results_user?.find(i => i.m_sPosition === '2')?.m_oCompetitor.m_sTeam}</p>
                    </div>
                  </div>

                  <div className='flex flex-col items-center justify-start text-white sm:justify-end'>
                    <p className='mb-4 text-lg font-semibold'>1</p>
                    <ProfilePicture glow={!!(results_user?.find(i => i.m_sPosition === '1')?.m_iPoints > 0)} image={results_user?.find(i => i.m_sPosition === '1')?.m_oCompetitor.m_sImageUrl} nationality={results_user?.find(i => i.m_sPosition === '1')?.m_oCompetitor.m_sNationality.toLowerCase() as Nationality} size='lg' />
                    <div className='flex flex-col items-center justify-center mt-4'>
                      {(results_user?.find(i => i.m_sPosition === '1')?.m_iPoints > 0) && <p className='text-sm font-medium text-tifo-light'>+{results_user?.find(i => i.m_sPosition === '1')?.m_iPoints} ptn</p>}
                      <p className='font-medium text-center uppercase'>{results_user?.find(i => i.m_sPosition === '1')?.m_oCompetitor.m_sName}</p><p className='text-sm text-center opacity-50'>{results_user?.find(i => i.m_sPosition === '1')?.m_oCompetitor.m_sTeam}</p>
                    </div>
                  </div>

                  <div className='flex flex-col items-center justify-start text-white sm:justify-end'>
                    <p className='mb-4 text-lg font-semibold'>3</p>
                    <ProfilePicture glow={!!(results_user?.find(i => i.m_sPosition === '3')?.m_iPoints > 0)} image={results_user?.find(i => i.m_sPosition === '3')?.m_oCompetitor.m_sImageUrl} nationality={results_user?.find(i => i.m_sPosition === '3')?.m_oCompetitor.m_sNationality.toLowerCase() as Nationality} size='md' />
                    <div className='flex flex-col items-center justify-center mt-4'>
                      {(results_user?.find(i => i.m_sPosition === '3')?.m_iPoints > 0) && <p className='text-sm font-medium text-tifo-light'>+{results_user?.find(i => i.m_sPosition === '3')?.m_iPoints} ptn</p>}
                      <p className='font-medium text-center uppercase'>{results_user?.find(i => i.m_sPosition === '3')?.m_oCompetitor.m_sName}</p><p className='text-sm text-center opacity-50'>{results_user?.find(i => i.m_sPosition === '3')?.m_oCompetitor.m_sTeam}</p>
                    </div>
                  </div>

                </div>
              </div>
              </> 
              }

            </div>
          </div>


          <div className='justify-center hidden py-16 mt-16 bg-white'>
            <div className='max-w-3xl mx-4 sm:mx-16'>
              <h2 className='mb-4 text-5xl font-bold'>Wat-Een-Wedstrijd!</h2>
              <p className='mb-2'>Jonas en de Tour. Het sprookje kreeg een gelukkig einde, maar een sprookje is geen sprookje zonder een stormachtige aanloop. De Deense wielrenner Jonas Vingegaard en zijn ploeg Jumbo-Visma weten daar inmiddels alles van.</p>
              <p>Aan de hand van drie bepalende momenten blikt de kersverse Tourwinnaar terug op de overwinning. Hoewel die zege daags na de huldiging op de Champs-Élysées nog steeds niet helemaal is doorgedrongen. "Ik besef het nog steeds niet."</p>
              
              <p className='mt-8 mb-4 font-semibold'>De Rampdag</p>
              <p>"Alles ging mis. Toen ik één minuut achterlag, dacht ik: dit is echt niet goed. Dit kan weleens de dag zijn dat...", blikt Vingegaard terug op de rampdag aan het begin van de Tour.
                Want in de vijfde etappe ging het helemaal mis voor de ploeg. Kopman Primoz Roglic verloor veel tijd door een vervelende val en ook Vingegaard raakte achterop na geklungel met zijn fiets.
                "Gelukkig heb ik de meest geweldige ploeggenoten ter wereld die mij gered hebben." Het gaf de renner juist energie en motivatie om nog beter zijn best te doen.
                Vingegaard: "Als iedereen je wil helpen en voor je wil werken, dan wil je natuurlijk ook iets teruggeven. Ik wilde echt voor de Tourzege vechten en mijn best doen, voor hen." En zo geschiedde.
              </p>

              <p className='mt-8 mb-4 font-semibold'>De matchsgreep</p>
              <p>Want na letterlijk vallen en opstaan greep Jumbo-Visma zes etappes later op de Col du Granon de macht. Het betekende voor Vingegaard zijn eerste ritzege én zijn eerste gele trui. Of hij die rit nog eens terug gaat kijken?
                "Ja dat denk ik wel. De samenwerking, het plan en de tactiek, alles deden we echt heel goed. We hadden niks te verliezen en uiteindelijk viel alles op zijn plek."
              </p>

              <p className='mt-8 mb-4 font-semibold'>De Eindzege</p>
              <p>In de laatste bergetappe ging het nog bijna mis toen Vingegaard onderuit leek te gaan in de afdaling. "Mijn hartslag was denk ik wel 220. Ik heb geen idee hoe ik het voor elkaar heb gekregen om niet te vallen", blikt de 25-jarige Deen terug.
                "Ik dacht wel: we komen dichterbij, maar we zijn er nog niet. De streep ligt in Parijs", zegt Vingegaard. "Wat als ik een slechte dag heb op de tijdrit en daar het geel verlies? Ik wilde het gewoon nog niet zeggen."
                Maar zelfs nu, na het terugblikken op zijn Tourzege, durft Vingegaard het nog niet hardop te zeggen. "Ik geloof eigenlijk nog steeds niet wat ik heb gedaan."
              </p>
            </div>
          </div>
          </>
        }
      </div>

      
    </div>
    </>
  )
}

export default Prono