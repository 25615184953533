import { Outlet } from "react-router-dom";
import Navbar from "../layouts/Navbar";
import { DepartmentProvider } from "../context/DepartmentProvider";

const Layout : React.FC = () => {
    return (
        <DepartmentProvider>
            <main className="flex flex-col">
                <Navbar />
                <Outlet />
            </main>
        </DepartmentProvider>
    )
}

export default Layout;