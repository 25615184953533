import { createContext, useEffect, useState } from "react";
import { Button } from "../components/ui/button";
import { Sheet, SheetClose, SheetContent } from "../components/ui/sheet";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAuth from "../hooks/useAuth";
import { useQuery } from "@tanstack/react-query";
import axios from "../lib/axios";
import { Skeleton } from "../components/ui/skeleton";
import { getCookie } from "../lib/cookies";


const DepartmentContext = createContext<null>(null);

export const DepartmentProvider = ({ children } : { children:React.ReactNode}) => {
    const {t} = useTranslation()
    const {auth} = useAuth()
    const location = useLocation()
    const [open_sheet, setopen_sheet] = useState(false);

    useEffect(()=>{
        if(auth.status === 'authenticated' && !location.pathname.includes('uitnodiging')){
            if(auth.data.m_oDepartment){
                if(auth.data.m_oDepartment.m_iPartnerID > 0){}
                else {
                    setopen_sheet(true)
                    return;
                }
            } else { setopen_sheet(true); return; }
        } else { setopen_sheet(false) }
    }, [location.pathname, auth.status])

    const { data:url, isLoading } = useQuery<string | null>({
        queryKey: [`get_invitation`],
        queryFn: async () => {
            try {
                const { data } = await axios.get(`get/invitation`)
                return data
            } catch (error) {
                console.log(error)
                return null
            }
        },
        enabled: open_sheet
    })

    const cookieUrl = getCookie('invitation-url') ? getCookie('invitation-url') : url

    return (
        <DepartmentContext.Provider value={null}>
            
            {children}

            <Sheet open={open_sheet}>
                <SheetContent className="mb-4 -translate-x-1/2 w-fit h-fit left-1/2 rounded-xl bg-tifo border-tifo-light" position="bottom" hideClose>
                    <div className="flex items-center gap-4 text-sm text-white">
                        <div>
                            <p className="max-w-sm">{t("explanation.o_department.popup")}</p>
                        </div>
                        {(isLoading) 
                        ? <Skeleton className="w-[150px] h-[40px] rounded-full"/>
                        :
                        (cookieUrl && <Link to={`${cookieUrl}`}>
                            <button className="h-10 px-4 font-medium duration-200 border rounded-full bg-white/20 hover:bg-white/30 whitespace-nowrap">{t("explanation.o_department.title")}</button>
                        </Link>
                        )
                        }
                    </div>
                </SheetContent>
            </Sheet>
        </DepartmentContext.Provider>
    )
}

export default DepartmentContext;